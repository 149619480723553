import React from "react";
import Icon from "@ant-design/icons";

const GovernanceSvg = () => (
  <svg viewBox="0 0 455 455" dth="1em" height="1em" fill="currentColor">
    <path
      d="M401.85,212.5c-3.156-37.002-17.873-70.752-40.518-97.619l37.641-37.641l-21.213-21.213l-37.642,37.641
	C313.252,71.023,279.502,56.306,242.5,53.15V0h-30v53.15c-37.002,3.156-70.752,17.873-97.619,40.518L77.239,56.027L56.026,77.24
	l37.641,37.641C71.023,141.748,56.306,175.498,53.15,212.5H0v30h53.15c3.156,37.002,17.873,70.752,40.518,97.619L56.026,377.76
	l21.213,21.213l37.642-37.641c26.867,22.645,60.617,37.362,97.619,40.518V455h30v-53.15c37.002-3.156,70.752-17.873,97.619-40.518
	l37.642,37.641l21.213-21.213l-37.641-37.641c22.645-26.867,37.362-60.617,40.518-97.619H455v-30H401.85z M242.5,83.272
	c28.75,2.968,55.029,14.376,76.324,31.69l-59.041,59.041c-5.299-3.21-11.113-5.645-17.283-7.171V83.272z M212.5,83.272v83.559
	c-6.17,1.526-11.984,3.962-17.283,7.171l-59.041-59.041C157.471,97.648,183.75,86.241,212.5,83.272z M114.963,136.176l59.041,59.04
	c-3.21,5.299-5.646,11.114-7.171,17.284h-83.56C86.241,183.75,97.648,157.471,114.963,136.176z M83.272,242.5h83.56
	c1.526,6.17,3.961,11.985,7.171,17.284l-59.041,59.04C97.648,297.529,86.241,271.25,83.272,242.5z M212.5,371.728
	c-28.75-2.968-55.029-14.376-76.324-31.69l59.041-59.041c5.299,3.21,11.113,5.645,17.283,7.171V371.728z M227.5,260
	c-17.921,0-32.5-14.579-32.5-32.5s14.579-32.5,32.5-32.5s32.5,14.579,32.5,32.5S245.421,260,227.5,260z M242.5,371.728v-83.559
	c6.17-1.526,11.984-3.962,17.283-7.171l59.041,59.041C297.529,357.352,271.25,368.759,242.5,371.728z M340.037,318.824
	l-59.041-59.04c3.21-5.299,5.646-11.114,7.171-17.284h83.56C368.759,271.25,357.352,297.529,340.037,318.824z M288.168,212.5
	c-1.526-6.17-3.961-11.985-7.171-17.284l59.041-59.04c17.314,21.295,28.722,47.575,31.69,76.324H288.168z"
    />
  </svg>
);

export const GovernanceIcon = (props) => (
  <Icon component={GovernanceSvg} {...props} />
);
