export const ADD_WALLET = "ADD_WALLET";
export const CHANGE_ACTIVE_WALLET = "CHANGE_ACTIVE_WALLET";
export const ADD_RECENT_STABLECOIN = "ADD_RECENT_STABLECOIN";
export const ADD_EXCHANGE = "ADD_EXCHANGE";
export const ADD_EXCHANGE_RECEPIENT = "ADD_EXCHANGE_RECEPIENT";
export const ADD_EXCHANGE_PENDING = "ADD_EXCHANGE_PENDING";
export const REMOVE_EXCHANGE_PENDING = "REMOVE_EXCHANGE_PENDING";
export const UPDATE_EXCHANGE_FORM = "UPDATE_EXCHANGE_FORM";
export const ADD_REFERRER = "ADD_REFERRER";
export const FIRST_VISIT = "FIRST_VISIT";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";