import React from "react";
import Icon from "@ant-design/icons";

const CapaciorSvg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 342"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M21.3333 248.133H42.6667V265.2C42.6667 274.626 50.3077 282.267 59.7333 282.267V333.467C59.7333 338.18 63.5538 342 68.2667 342C72.9795 342 76.8 338.18 76.8 333.467V282.267C86.2257 282.267 93.8667 274.626 93.8667 265.2V248.133H162.133V265.2C162.133 274.626 169.774 282.267 179.2 282.267V333.467C179.2 338.18 183.021 342 187.733 342C192.446 342 196.267 338.18 196.267 333.467V282.267C205.692 282.267 213.333 274.626 213.333 265.2V248.133H234.667C246.449 248.133 256 238.582 256 226.8V218.267C256 206.485 246.449 196.933 234.667 196.933C232.31 196.933 230.4 195.023 230.4 192.667V184.133C230.4 181.777 232.31 179.867 234.667 179.867C240.325 179.867 245.751 177.619 249.752 173.618C253.752 169.618 256 164.191 256 158.533V34.1333C256 15.282 240.718 0 221.867 0H34.1333C15.282 0 0 15.282 0 34.1333V158.533C0 164.191 2.24761 169.618 6.24839 173.618C10.2492 177.619 15.6754 179.867 21.3333 179.867C23.6897 179.867 25.6 181.777 25.6 184.133V192.667C25.6 195.023 23.6897 196.933 21.3333 196.933C9.55126 196.933 0 206.485 0 218.267V226.8C0 238.582 9.55126 248.133 21.3333 248.133ZM76.8 265.2H59.7333V248.133H76.8V265.2ZM196.267 265.2H179.2V248.133H196.267V265.2ZM238.933 226.8C238.933 229.156 237.023 231.067 234.667 231.067H21.3333C18.9769 231.067 17.0667 229.156 17.0667 226.8V218.267C17.0667 215.91 18.9769 214 21.3333 214H234.667C237.023 214 238.933 215.91 238.933 218.267V226.8ZM17.0667 34.1333C17.0667 24.7077 24.7077 17.0667 34.1333 17.0667H221.867C231.292 17.0667 238.933 24.7077 238.933 34.1333V158.533C238.933 160.89 237.023 162.8 234.667 162.8H21.3333C18.9769 162.8 17.0667 160.89 17.0667 158.533V34.1333ZM42.2315 179.867H213.751C213.463 181.271 213.318 182.7 213.316 184.133V192.667C213.318 194.1 213.463 195.529 213.751 196.933H42.2315C42.5195 195.529 42.6653 194.1 42.6667 192.667V184.133C42.6653 182.7 42.5195 181.271 42.2315 179.867Z" />
    <path d="M21.3333 248.133H42.6667V265.2C42.6667 274.626 50.3077 282.267 59.7333 282.267V333.467C59.7333 338.18 63.5538 342 68.2667 342C72.9795 342 76.8 338.18 76.8 333.467V282.267C86.2257 282.267 93.8667 274.626 93.8667 265.2V248.133H162.133V265.2C162.133 274.626 169.774 282.267 179.2 282.267V333.467C179.2 338.18 183.021 342 187.733 342C192.446 342 196.267 338.18 196.267 333.467V282.267C205.692 282.267 213.333 274.626 213.333 265.2V248.133H234.667C246.449 248.133 256 238.582 256 226.8V218.267C256 206.485 246.449 196.933 234.667 196.933C232.31 196.933 230.4 195.023 230.4 192.667V184.133C230.4 181.777 232.31 179.867 234.667 179.867C240.325 179.867 245.751 177.619 249.752 173.618C253.752 169.618 256 164.191 256 158.533V34.1333C256 15.282 240.718 0 221.867 0H34.1333C15.282 0 0 15.282 0 34.1333V158.533C0 164.191 2.24761 169.618 6.24839 173.618C10.2492 177.619 15.6754 179.867 21.3333 179.867C23.6897 179.867 25.6 181.777 25.6 184.133V192.667C25.6 195.023 23.6897 196.933 21.3333 196.933C9.55126 196.933 0 206.485 0 218.267V226.8C0 238.582 9.55126 248.133 21.3333 248.133ZM76.8 265.2H59.7333V248.133H76.8V265.2ZM196.267 265.2H179.2V248.133H196.267V265.2ZM238.933 226.8C238.933 229.156 237.023 231.067 234.667 231.067H21.3333C18.9769 231.067 17.0667 229.156 17.0667 226.8V218.267C17.0667 215.91 18.9769 214 21.3333 214H234.667C237.023 214 238.933 215.91 238.933 218.267V226.8ZM17.0667 34.1333C17.0667 24.7077 24.7077 17.0667 34.1333 17.0667H221.867C231.292 17.0667 238.933 24.7077 238.933 34.1333V158.533C238.933 160.89 237.023 162.8 234.667 162.8H21.3333C18.9769 162.8 17.0667 160.89 17.0667 158.533V34.1333ZM42.2315 179.867H213.751C213.463 181.271 213.318 182.7 213.316 184.133V192.667C213.318 194.1 213.463 195.529 213.751 196.933H42.2315C42.5195 195.529 42.6653 194.1 42.6667 192.667V184.133C42.6653 182.7 42.5195 181.271 42.2315 179.867Z" />
  </svg>
);

export const CapacitorIcon = (props) => (
  <Icon component={CapaciorSvg} {...props} />
);
