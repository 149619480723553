import React from "react";
import Icon from "@ant-design/icons";

const FundSvg = () => (
  <svg
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 416 512" xmlns="http://www.w3.org/2000/svg">
    <path d="M288 151.913V115.781L334.313 23.156C336.79 18.195 336.524 12.304 333.61 7.586C330.695 2.875 325.547 0 320 0H256C251.758 0 247.687 1.688 244.687 4.688L224 25.375L203.313 4.688C200.313 1.688 196.243 0 192 0H96C90.453 0 85.305 2.875 82.391 7.586C79.477 12.305 79.211 18.195 81.688 23.156L128 115.781V151.913C56.824 204.277 0 309.69 0 391.648C0 512 105.742 512 208 512C310.258 512 416 512 416 391.648C416 309.69 359.176 204.277 288 151.913ZM160 128H256V144H160V128ZM185.375 32L212.688 59.313C218.938 65.563 229.063 65.563 235.313 59.313L262.625 32H294.109L262.109 96H208H153.891L121.891 32H185.375ZM208 480C82.937 480 32 472.008 32 391.648C32 326.914 78.844 226 149.008 176H266.992C337.156 226 384 326.914 384 391.648C384 472.008 333.063 480 208 480Z" fill="currentColor" />
    <path d="M258.768 292.473C253.775 287.484 253.775 279.395 258.768 274.405L266.566 266.608C271.546 261.619 279.636 261.619 284.628 266.608C289.621 271.596 289.621 279.686 284.628 284.676L276.83 292.473C271.837 297.463 263.748 297.463 258.768 292.473Z" fill="currentColor" />
    <path d="M256.113 392.625C261.093 387.635 269.183 387.635 274.175 392.625L284.502 402.946C289.482 407.937 289.482 416.026 284.502 421.015C279.509 426.005 271.42 426.005 266.427 421.015L256.113 410.693C251.121 405.703 251.121 397.614 256.113 392.625Z" fill="currentColor" />
    <path d="M128.302 269.876C133.294 264.886 141.384 264.886 146.376 269.876L156.475 279.984C161.468 284.973 161.468 293.062 156.475 298.053C151.495 303.041 143.406 303.041 138.414 298.053L128.302 287.945C123.309 282.955 123.309 274.865 128.302 269.876Z" fill="currentColor" />
    <path d="M132.941 418.929C127.961 413.94 127.961 405.851 132.941 400.861L141.182 392.625C146.174 387.635 154.264 387.635 159.244 392.625C164.236 397.614 164.236 405.703 159.244 410.693L151.015 418.929C146.023 423.919 137.933 423.919 132.941 418.929Z" fill="currentColor" />
    <path d="M84 339.542C84 332.486 88.6013 326.766 94.2692 326.766H123.025C128.693 326.766 133.294 332.486 133.294 339.542C133.294 346.598 128.693 352.318 123.025 352.318H94.2692C88.6013 352.318 84 346.598 84 339.542Z" fill="currentColor" />
    <path d="M281.772 339.542C281.772 332.486 286.373 326.766 292.041 326.766H320.797C326.465 326.766 331.066 332.486 331.066 339.542C331.066 346.598 326.465 352.318 320.797 352.318H292.041C286.373 352.318 281.772 346.598 281.772 339.542Z" fill="currentColor" />
    <path d="M282.265 341.334C282.265 382.28 249.061 415.479 208.096 415.479C167.131 415.479 133.926 382.28 133.926 341.334C133.926 300.39 167.131 267.19 208.096 267.19C249.061 267.19 282.265 300.39 282.265 341.334Z" fill="white" stroke="currentColor" strokeWidth="25" />
    <path d="M206.604 265.294C199.627 265.294 193.965 261.282 193.965 256.332V224.963C193.965 220.013 199.627 216 206.604 216C213.581 216 219.244 220.013 219.244 224.963V256.332C219.244 261.282 213.581 265.294 206.604 265.294Z" fill="currentColor" />
    <path d="M206.604 465C199.627 465 193.965 460.987 193.965 456.037V424.668C193.965 419.718 199.627 415.706 206.604 415.706C213.581 415.706 219.244 419.718 219.244 424.668V456.037C219.244 460.987 213.581 465 206.604 465Z" fill="currentColor" />
  </svg>
);

export const FundIcon = (props) => (
  <Icon component={FundSvg} {...props} />
);
